:root {
  --secondary-glow: conic-gradient(
    from 10deg at 50% 50%,
    #eb7494 0deg,
    #ae77b2 55deg,
    #97b5da 120deg,
    #0099ca 160deg,
    transparent 360deg
  );

  --third-glow: conic-gradient(
    from 90deg at 30% 50%,
    #172554 0deg,
    #172554 160deg,
    #111827 120deg,
    #1d4ed8 55deg,
    transparent 360deg
  );
  --range-thumb-size: 36px;
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  animation: spin 1s linear infinite;
}

.h-50vh-minus-100 {
  height: calc(50vh - 100px);
}

.message-text {
  text-align: center;
  letter-spacing: 0.44px;
  color: #778da9;
  opacity: 1;
  font: normal normal normal 22px/33px Poppins;
  margin-top: 1.8rem;
}

.message-sub-text {
  text-align: center;
  font: normal normal medium 22px/33px Poppins;
  letter-spacing: 0.44px;
  color: #778da9;
  opacity: 1;
  margin-top: 0.5rem;
}

.text-error {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  text-align: left;
  margin-top: 3px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  color: #f44336;
}

.gradient {
}

@keyframes animateBefore {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(200px) scale(0.8);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes animateAfter {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-250px) scale(1.2);
  }

  100% {
    transform: translateX(0);
  }
}

.gradient::before {
  content: "";
  position: absolute;
  background: var(--third-glow);
  border-radius: 50%;
  width: 50vw;
  height: 30vw;
  margin-left: -25vw;
  /* Adjusted to be responsive */
  filter: blur(90px);
  top: 10vh;
  /* Simplified to potentially better suit various screen sizes */
  left: 30vw;
  /* Adjusted to center based on width */
  animation: animateBefore 7s cubic-bezier(0.47, 0, 0.745, 0.715) infinite;
  z-index: 1;
  /* Ensure it's behind the content */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .gradient::before {
    width: 100vw;
    /* Increase size for smaller screens if needed */
    height: 150vw;
    /* Keep it proportional */
    margin-left: -25vw;
    /* Adjust according to new width */
    top: 15vh;
    /* Adjust for smaller screen height */
    left: 15vw;
    /* Center on smaller screens */
    filter: blur(50px);
    /* Adjust blur effect for smaller element */
  }
}

.dzu-dropzone {
  overflow: hidden !important;
}

/* Extra small devices (phones, less than 768px) */
@media (max-width: 767.98px) {
  .custom-paper-plane {
    right: 10%; /* Adjust as needed */
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .custom-paper-plane {
    right: 15%; /* Adjust as needed */
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .custom-paper-plane {
    right: 18%; /* Adjust as needed */
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .custom-paper-plane {
    right: 47%; /* Adjust as needed */
  }
}

input[type="checkbox"]:checked:after,
input[type="checkbox"]:checked::after {
  background-color: #1b263b !important;
}

.tox .tox-menubar {
  background-color: #334155 !important;
}

.tox .tox-mbtn__select-label {
  color: #fff !important;
}

.message-container
  .tox.tox-tinymce
  .tox-editor-container
  .tox-editor-header
  .tox-toolbar-overlord
  .tox-toolbar__primary {
  background-color: #334155 !important;
}

.tox :not(svg):not(rect) {
  fill: #fff;
}

.tox .tox-mbtn {
  background: #1E293B !important;
}

.tox .tox-tbtn {
  background: #1E293B !important;
}

.tox-toolbar__primary {
  background-color: #334155 !important;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
  background-color: #334155 !important;
}

.tox-tbtn__select-label {
  color: #fff !important;
}

.css-nk89i7-MuiPickersCalendarHeader-root {
  color: #fff;
}

.css-1r39cda {
  color: #fff;
}

.css-18l6614-MuiButtonBase-root-MuiPickersDay-root {
  color: #fff;
}

.MuiPickersDay-today {
  color: #fff;
}

.MuiButtonBase-root.Mui-disabled.MuiPickersDay-root.Mui-disabled.MuiPickersDay-dayWithMargin {
  color: #fff;
  opacity: 0.5;
}

.css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon {
  color: #fff;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium.MuiPickersArrowSwitcher-button.css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
  color: #fff;
}

.MuiButtonBase-root.Mui-disabled.MuiIconButton-root.Mui-disabled.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium.MuiPickersArrowSwitcher-button.css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
  color: #fff;
  opacity: 0.5;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeStart.MuiIconButton-sizeMedium.MuiPickersArrowSwitcher-button.css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
  color: #fff;
}

.MuiPickersYear-yearButton.css-1q9myda-MuiPickersYear-yearButton {
  color: #fff;
}

.css-bmoxj4-MuiList-root-MuiMultiSectionDigitalClockSection-root {
  border-right: 1px solid #fff;
}

.css-bmoxj4-MuiList-root-MuiMultiSectionDigitalClockSection-root:last-child {
  border-right: none;
}

.css-1r39cda {
  border: 1px solid #778da9;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.css-1g7nc1s-MuiPickersLayout-root .MuiPickersLayout-actionBar {
  border-bottom: 1px solid #778da9; /* Border at the bottom */
  border-left: 1px solid #778da9; /* Border at the left */
  border-right: 1px solid #778da9; /* Border at the right */
  border-top: none; /* No border at the top */
  border-bottom-left-radius: 10px; /* Rounded corner at the bottom-left */
  border-bottom-right-radius: 10px; /* Rounded corner at the bottom-right */
}

.css-h7c8u7-MuiListItemText-root .MuiListItemText-primary {
  color: #fff;
}

.css-utkqj3 {
  color: #e1dddd;
}

.css-sldnni {
  color: #fff;
}

.tox .tox-edit-area a {
  color: lightblue !important;
}

.custom-link-class {
  color: lightblue !important;
}

.MuiPickersClock-pin {
  color: white !important;
}

/* .MuiList-root.MuiList-padding.MuiMultiSectionDigitalClock-root.css-bmoxj4-MuiList-root-MuiMultiSectionDigitalClockSection-root {
  border: "1px solid #fff";
} */

/* 3874CB */

/* 3874CB */

/* input[type="checkbox"] {
  filter: #1b263b;
} */
.pulse {
  transform: scale(1);
  opacity: 0;
  transform-origin: center;
  animation: pulseLoop 8000ms linear infinite;
}

.pulse-1 {
  animation-delay: -2000ms;
}

.pulse-2 {
  animation-delay: -4000ms;
}

.pulse-3 {
  animation-delay: -6000ms;
}
@keyframes pulseLoop {
  0% {
    opacity: 0;
    transform: scale(1) translateZ(0);
  }

  30% {
    opacity: 0.4;
  }

  60% {
    opacity: 0;
  }

  80% {
    opacity: 0;
    transform: scale(2.5) translateZ(0);
  }
}

.css-mhc70k-MuiGrid-root > .MuiGrid-item {
  padding-left: 0px !important;
}

.scrollable-list {
  max-height: 600px; /* Adjust the height as needed */
  overflow-y: auto;
}

/* .tox .tox-mbtn__select-label {
  color: #000 !important;
} */
