@charset "UTF-8";

// 1. Configuration and helpers
@import "abstracts/variables", "abstracts/functions", "abstracts/mixins";

// 2. Base stuff
@import "base/base", "base/fonts", "base/typography", "base/helpers";

// 3. Layout-related sections
@import "layout/header", "layout/footer";

// 4. Components
@import "components/button";

// 5. Page-specific styles
@import "pages/home", "pages/person-detail", "pages/authentication";

// 6. Themes
@import "themes/default";

// 7. Vendors
@import "vendors/rc-pagination";

body, #root {
  height: 100vh
}

.custom-list-container ul {
  list-style-type: disc; 
  list-style-position: inside;
  padding-left: 20px;
}

.custom-list-container ol {
  list-style-type: decimal;
  list-style-position: inside;
  padding-left: 20px;
}