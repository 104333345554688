hr.divider.sign-in {
  height: 1px;
  margin: 15px 0 0;
  padding: 0;
  overflow: visible;
  border: none;
  background-color: #e7e7e9;
  color: #6e6d7a;
  text-align: center;

  // &:after {
  //   content: "or sign in with username";
  //   display: inline-block;
  //   position: relative;
  //   top: -7px;
  //   padding: 0 16px;
  //   background: #fff;
  //   font-size: 14px;
  // }
}

.google-button-wrapper div {
  height: auto !important;
}

@media (min-width: 1024px) {
  .google-button-wrapper span {
    font-size: 18px;
  }
}
