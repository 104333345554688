// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.MuiDialog-root,
.MuiBox-root {
  .MuiDialogActions-root {
    button {
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.32px;
      text-transform: uppercase;
      padding: 13px 25px;

      @media (min-width: 1280px) {
        font-size: 16px;
      }
    }
  }
}
